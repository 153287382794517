import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Button, Accordion, useAccordionToggle, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import backArrow from '../../../assets/icons/back-gray.svg';
import { ManageCookiesContext } from '../../../contexts/ManageCookiesContext.jsx';

export default function ManageCookies ({ setActiveView }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setCookiesData, setSelected, setShowDialog } = React.useContext(ManageCookiesContext);
  const [data, setData] = React.useState({
    strictly_necessary_cookie: true,
    statistics_cookie: true,
    preferences_cookie: true,
    marketing_cookie: true
  });
  const [openSection, setOpenSection] = React.useState(null);

  const handleChange = (e) => {
    const { id } = e.target;
    const newData = { ...data, [id]: !data[id] };
    setData(newData);
  };

  const handleConfirm = () => {
    setCookiesData(data);
    setSelected(true);
    setShowDialog(false);
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <h4 className={classes.header}>{t('cookies.header')}</h4>
      </div>
      <Accordion>
        <SectionHeader eventKey="0" openSection={openSection} setOpenSection={setOpenSection}>
          <Form.Check
            type="switch"
            id="strictly_necessary_cookie"
            disabled
            checked={data['strictly_necessary_cookie']}
          />
        </SectionHeader>
        <Accordion.Collapse eventKey="0">
          <div className={classes.accordionBody}>
            {t('cookies.manage.section0.content')}
          </div>
        </Accordion.Collapse>
        <SectionHeader eventKey="2" openSection={openSection} setOpenSection={setOpenSection}>
          <Form.Check
            type="switch"
            id="statistics_cookie"
            onChange={handleChange}
            checked={data['statistics_cookie']}
          />
        </SectionHeader>
        <Accordion.Collapse eventKey="2">
          <div className={classes.accordionBody}>
            {t('cookies.manage.section2.content')}
          </div>
        </Accordion.Collapse>
        <SectionHeader eventKey="1" openSection={openSection} setOpenSection={setOpenSection}>
          <Form.Check
            type="switch"
            id="preferences_cookie"
            onChange={handleChange}
            checked={data['preferences_cookie']}
          />
        </SectionHeader>
        <Accordion.Collapse eventKey="1">
          <div className={classes.accordionBody}>
            {t('cookies.manage.section1.content')}
          </div>
        </Accordion.Collapse>
        <SectionHeader eventKey="3" openSection={openSection} setOpenSection={setOpenSection}>
          <Form.Check
            type="switch"
            id="marketing_cookie"
            onChange={handleChange}
            checked={data['marketing_cookie']}
          />
        </SectionHeader>
        <Accordion.Collapse eventKey="3">
          <div className={classes.accordionBody}>
            {t('cookies.manage.section3.content')}
          </div>
        </Accordion.Collapse>
      </Accordion>
      <div style={{ textAlign: 'right', position: 'relative' }}>
      <div
          style={{
            position: 'absolute',
            left: '0px',
            top: '28px',
            width: '25px',
            height: '25px',
            border: '1px lightgrey solid',
            borderRadius: '10000px',
            display: 'flex',
            alignItems: 'center',
            justifyContent : 'center'
          }}
          onClick={() => setActiveView('privacy')}
        >
          <img src={backArrow} style={{ width: '6px' }} alt="" />
        </div>
        <Button
          style={{ backgroundColor: '#3dabe0', borderColor: '#3dabe0', margin: '20px 0 70px 0' }}
          className={classes.button}
          onClick={handleConfirm}
        >
          {t('cookies.manage.button')}
        </Button>
      </div>
    </>
  );
}

function SectionHeader ({ children, eventKey, openSection, setOpenSection }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    if (openSection !== eventKey) {
      setOpenSection(eventKey);
    } else {
      setOpenSection(null);
    }
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const isOpen = openSection === eventKey;

  return (
    <Box
      className={classes.accordionHeader}
      onClick={decoratedOnClick}
    >
      <span style={{ verticalAlign: 'middle', fontSize: '25px', margin: isOpen ? '17px' : '15px' }}>{isOpen ? '-' : '+'}</span>
      <h6 style={{ margin: '0' }} className={classes.header}>{t(`cookies.manage.section${eventKey}.title`)}</h6>
      <Box flexGrow={1} />
      {children}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#343288',
    marginBottom: '10px',
  },
  paragraph: {
    fontWeight: 100,
    color: 'grey',
    fontSize: '13px',
    margin: '20px 0',
  },
  button: {
    borderRadius: '30px',
    padding: '7px 30px',
  },
  accordionHeader: {
    border: '1px #F0F0F0 solid',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  accordionBody: {
    border: '1px #F0F0F0 solid',
    padding: '10px 40px',
    fontWeight: 100,
    color: 'grey',
    fontSize: '13px',
    [theme.breakpoints.down('500')]: {
      padding: '10px 20px',
    }
  }
}))
