/* eslint-disable */
import React, { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser';

import heroBanner from './../../assets/images/vendor-hero-2.png';
import robotAssistant from './../../assets/images/robot.svg';
import robotWizardFlow from './../../assets/images/robot-wizard.svg';
import { Helmet } from "react-helmet";

import Header from "../components/landing/Header";
import Logos from "../components/landing/logos";
import Footer from "../components/landing/footer";
import Section6 from "../components/landing/section_6";
import PricingTable from "../components/wizard/PricingTable";

import axios from "axios";
import { stateIsLoaded } from "../../services/stateHelpers";
import { useDispatch, useSelector } from "react-redux";
import { data } from "jquery";
import { Link, useHistory, useParams } from 'react-router-dom';
import { GET_SINGLE_INSIGHT_API } from "../../services/api";
import { browserHistory } from 'react-router';
import * as qs from 'query-string';
import Step5 from "../components/wizard/steps/Step5";

import landingData from '../../assets/api/mk/front.json'
import { useTranslation } from 'react-i18next';
function Insights(props) {

  const { t } = useTranslation();
  const wizardStateData = useSelector(state => state.wizardState.data);
  const wizardData = useSelector(state => state.wizard);

  const { push } = useHistory()
  let { params } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [insightData, setInsightData] = useState();
  const [isInsightShown, setIsInsightShown] = useState(false);
  const [shownInsightId, setShownInsightId] = useState(0);

  // const insightClicked = (id) => {
  //   setIsInsightShown(true);
  //   setShownInsightId(id);
  //   fetchInsight(id);
  //   push('/insights?insight=' + id)
  // }

  const fetchInsight = async (id) => {
    setIsLoading(true);
    const result = await axios(
      GET_SINGLE_INSIGHT_API + "/" + id
    );
    setInsightData(result.data);
    setIsLoading(false);
  }

  const handleBackClick = () => {

    console.log("BACK " + isInsightShown);
    if (isInsightShown)
      setIsInsightShown(false);
  }

  const { listen } = useHistory()
  useEffect(() => {
    const unlisten = listen((location) => {
      handleBackClick();
    })

    // remember, hooks that add listeners
    // should have cleanup to remove them
    return unlisten
  }, [])

  return (
    <div>
       <Helmet titleTemplate="Vendor - %s">
        <meta charSet="utf-8" />
        <title>Планови - Vendor</title>
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png"/>
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png"/>
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
        <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/manifest.json"/>
        <meta name="msapplication-TileColor" content="#ffffff"/>
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
        <meta name="theme-color" content="#ffffff"/>
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta name="author" content="Vendor" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />

        <meta property="og:title" content="Vendor - Rethink selling" />
        <meta
          property="og:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          property="og:image"
          content="https://vendor.com.mk/facebook-thumbnail.jpg"
        />
        <meta property="og:url" content="https://vendor.com.mk/" />

        <meta name="twitter:title" content="Vendor - Rethink selling" />
        <meta
          name="twitter:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          name="twitter:image"
          content="https://vendor.com.mk/twitter-thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div>
        <Header></Header>

        <div className="insightsWrapper mb-4 py-5">
          <div className="container">
            {/* Title */}
            <div class="col-md-8 mx-auto">
              <div className=" justify-content-center">
                <h3 className="custom-label text-center">
                  {t('plans.header')}
            </h3>
                {/* Description */}
                <h6 className="c-indigo text-center" style={{ fontSize: 16 }} >
                  {t('plans.subHeader1')}<br/>{t('plans.subHeader2')}
            </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="container">

         <PricingTable />
          <Section6
            
            title={landingData?.section_6?.title}
            button_label={landingData?.section_6?.button_label}
            key={"seciont 6"}
          />
        </div>
        <Footer />
      </div>
      {/* )} */}
    </div>
  );
}

export default Insights;
