/* eslint-disable */
import React from 'react';
import Header from './Header';
import Footer from './footer';
import Section6 from './section_6';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ReactHtmlParser from 'react-html-parser';
import faqData from '../../../assets/api/mk/faq.json';
import { useTranslation } from 'react-i18next';

function Faq () {
  const { t } = useTranslation();
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div>
      <Header />
      <div className="insightsWrapper mb-4 py-5">
        <div className="container">
          <div className=" justify-content-start">
            <h3 className="custom-label ">
              {t('faq.header')}
            </h3>
            <h6 className="c-indigo" style={{ fontSize: 16 }}>
              {t('faq.subheader')}
            </h6>
          </div>
        </div>
      </div>
      <div className="container">
        
        {faqData.questions.map(
          (question) => {
            return (
              <div id="faq-accordion">
                <Accordion defaultActiveKey="Accordion">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        className="p-0"
                        variant="link"
                        eventKey="0"
                      >
                        <h6 className="fs-16 c-dark-blue fw-500 m-0 single-question text-left">
                          {ReactHtmlParser(question.title)}
                        </h6>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        {' '}
                        {ReactHtmlParser(question.text)}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            );
          },
        )}
      </div>
      <Section6
        title="Биди дел од светот на Vendor!"
        button_label="Регистрирај се"
        key={'seciont 6'}
      />
      <Footer />
    </div>
  );
}

export default Faq;
