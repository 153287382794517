import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ManageCookiesContext } from '../../../contexts/ManageCookiesContext.jsx';
import { Link } from 'react-router-dom';

export default function CookiePrivacy ({ setActiveView }) {
  const { setCookiesData, setSelected,setShowDialog } = React.useContext(ManageCookiesContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleDisagreeClick = () => {
    setShowDialog(false);
  };
  const handleAgreeClick = () => {
    setCookiesData({
      strictly_necessary_cookie: true,
      statistics_cookie: true,
      preferences_cookie: true,
      marketing_cookie: true
    });
    setSelected(true);
    setShowDialog(false);
  };
  return (
    <>
      <h4 className={classes.header}>{t('cookies.header')}</h4>
      <p className={classes.paragraph}>{t('cookies.paragraph1')}</p>
      <p className={classes.paragraph}>{t('cookies.paragraph2')}</p>
      <Box display="flex" justifyContent="space-between" className={classes.buttonContainer}>
        <Button
          variant="outline-secondary"
          className={classes.button}
          onClick={handleDisagreeClick}
        >
          {t('cookies.disagree')}
        </Button>
        <Button
          style={{ backgroundColor: '#3dabe0', borderColor: '#3dabe0' }}
          className={classes.button}
          onClick={handleAgreeClick}
        >
          {t('cookies.agree')}
        </Button>
      </Box>
      <Box display="flex" flexDirection="column" style={{ marginTop: '20px' }}>
        <div
          className={classes.link}
          style={{ color: '#343288' }}
          onClick={() => setActiveView('manageCookies')}
        >
          {t('cookies.manageCookiesLink')}
        </div>
        <Link target="_blank" to="/public-files/documents/mk/cookie-policy.pdf" className={classes.link}>
          {t('cookies.info')}
        </Link>
      </Box>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#343288',
  },
  paragraph: {
    fontWeight: 100,
    color: 'grey',
    fontSize: '13px',
    margin: '20px 0',
  },
  button: {
    borderRadius: '30px',
    padding: '7px 15px',
    marginTop: '5px',
    width: '100%',
    marginRight: '5px'
  },
  buttonContainer: {
    [theme.breakpoints.down('430')]: {
      flexWrap: "wrap",
      flexDirection:"column-reverse"
    }
  },
  link: {
    cursor: 'pointer',
    margin: '5px 0',
    textDecoration: 'underline',
    color: 'grey',
    '&:hover': {
      color: 'grey',
    },
  },
}));
