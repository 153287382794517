import { createMuiTheme } from '@material-ui/core/styles';
import { palette } from './../theme/theme-constants';
import themeOverrides from './overrides';

export const theme = createMuiTheme({
    palette,
    overrides: themeOverrides.overrides,
    typography: {
        fontFamily: "'Greenwich',sans-serif",
        subtitle1: {
            fontWeight: 900,
            color: palette.description.main,
        },
        body1:{
            fontFamily:  "'Greenwich',sans-serif",
            fontSize:'15px',
            lineHeight: '19px'
        }
    },
});
