/* eslint-disable */
import Slider from "react-slick";

function logos(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          arrows:true
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="row mx-0 logos-container">
        <div className="col-md-12 px-4">
          {/* <h1 className="ff-literal fs-36 text-center c-dark-blue lh-45">{logoArrays}</h1> */}
          <div id="carousel-logos" className="justify-content-center w-100">
            <Slider {...settings} className="p-4">
              {props.logos.map((logo) => {
                return (
                  <div className="justify-content-center d-flex">
                    <img width={200} height="100%" src={logo.src} alt={logo.alt}/>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default logos;
