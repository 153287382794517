/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Chrono } from "react-chrono";


function Timeline({background, text, title, timeline}){


    return (


          <div style={ {backgroundImage: 'url(' + background + ')'}} className="bg-pale-blue my-3 py-md-5 py-0" id="timeline-wrapper">
          <div className="container">
              <div className="row mb-5 pb-4">

                  <div className="col-md-12 mt-md-5 mt-5 mb-md-5 mb-0">
                      <h2 className="ff-literal fs-36 text-center c-white lh-45 text-center">{title}</h2>
                      <h6 className="how-to-steps-description py-2 c-white text-center">{text}</h6>
                  </div>

                  {
                      timeline.map((step, i) => {
                          return (
                              <div key={i + 2} className="col-lg-3 col-md-6 col-sm-6 col-xs-12 single-step-wrapper">

                                  <div className="steps-text-wrapper">
                                      <div className="steps-number-heading-wrap">
                                          <div className="text-center timeline-circle"></div>
                                          <h5 className="timeline-heading c-white">{step.date}</h5>
                                          <h6 className="how-to-steps-heading c-white">{step.title}</h6>
                                      </div>
                                      <p className="how-to-steps-description c-white">
                                          {step.text}
                                      </p>
                                  </div>
                              </div>
                          )
                      })
                  }
              </div>


          </div>
      </div>
      )

}
export default Timeline;

 // <div style={{ backgroundImage: 'url(' + background + ')', height: 600, width:"100%", justifyContent: 'center' }}>
          /* <Chrono items={items} hideControls={true} cardPositionHorizontal="BOTTOM" useReadMore={false} theme={{primary: "white", secondary: "white", cardBgColor: "white", cardForeColor: "white" }} /> */
