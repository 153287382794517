import * as React from 'react';
import { ThemeProvider } from './services/theme/theme-provider';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { theme } from './services/mui-theme';
import HomePage from './module/screens/HomePage';
import BookMeeting from './module/components/landing/BookMeeting/BookMeeting.js'
import Faq from './module/components/landing/faq';
import CustomerService from './module/components/landing/customer_service';
import Register from './module/screens/Register';
import Partners from './module/screens/Partners';
import Modules from './module/screens/Modules';
import EmailServer from './module/screens/EmailServer';
import Servers from './module/screens/Servers';
import VendorAPI from './module/screens/VendorAPI';
import Plans from './module/screens/Plans';
import About from './module/screens/About';
import BookMeetingSuccesPage from './module/components/landing/BookMeeting/BookMeetingSuccesPage'
import LeadSuccessfulBusiness from "./module/screens/LeadSuccessfulBusiness";

import LeadSuccessful from './module/screens/LeadSuccessful';

import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';
import { store } from './redux/config/store.js';
import VendorDocuments from './module/screens/VendorDocuments.js';
import WithManageCookiesData from './contexts/ManageCookiesContext.jsx';
import WithVariousPropContext from './contexts/VariousPropContext';
import Cookies from "./module/components/cookies/Cookies";
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';

export const LocaleContext = React.createContext('mk');


LinkedInTag.init('4062609', 'dc', false);
LinkedInTag.track('6681001');

function App () {
  const [locale, setLocale] = React.useState('mk');


  const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID,
    };
    TagManager.initialize(tagManagerArgs);

      const options = {
        autoConfig: true,
        debug: false,
      };
      ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, null, options);
      ReactPixel.pageView();

  return (
    <div className="App" style={{ height: '100vh' }}>
      <ThemeProvider>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <WithVariousPropContext>
          <WithManageCookiesData>
            <LocaleContext.Provider value={{ locale, setLocale }}>
              <Provider store={store}>
                <Router>
                  <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/home" component={HomePage} />
                    <Route path="/faq" component={Faq} />
                    <Route path="/customer-service" component={CustomerService} />
                    <Route path="/register-business" component={Register} />
                    <Route path="/register" component={Register} />
                    <Route path="/successful-register" component={LeadSuccessful} />
                    <Route path="/successful-register-business" component={LeadSuccessfulBusiness} />
                    {/* <Route path="/successful-book-meeting" component={BookMeetingSuccesPage} /> */}
                    <Route path="/partners" component={Partners} />
                    <Route path="/modules" component={Modules} />
                    <Route path="/email-server" component={EmailServer} />
                    <Route path="/servers" component={Servers} />
                    <Route path="/vendor-api" component={VendorAPI} />
                    <Route path="/about" component={About} />
                    <Route path="/plans" component={Plans} />
                    <Route path="/vendor-documents" component={VendorDocuments} />
                    {/* <Route path="/book-meeting" component={BookMeeting} /> */}
                  </Switch>
                  <Cookies />
                </Router>
              </Provider>
            </LocaleContext.Provider>
          </WithManageCookiesData>
          </WithVariousPropContext>
        </MuiThemeProvider>
      </ThemeProvider>

    </div>
  );
}

export default App;
