import React from "react";
import {useTranslation} from "react-i18next";

export const FormIcons = ({business}) => {
    const {t} = useTranslation();

    return  business ? <div className="row  col-lg-11 col-xl-11 col-md-12 font-weight-bold c-magenta my-3 mx-auto">
        <div className="col-md-4  text-md-center text-left my-2">
            <div className="row mx-0">
                <div className="col-md-6 col-4 mx-auto">
                    <img src="/free-registration.svg" className="img-fluid" />
                </div>
                <div className="col-md-12 col-8">
                    <h6 className="mt-2">{t('register.earlyBird')}</h6>
                </div>
            </div>
        </div>

        <div className="col-md-4  text-md-center text-left my-2">
            <div className="row mx-0">
                <div className="col-md-6 col-4 mx-auto">
                    <img src="/4-step-integration.svg" className="img-fluid" />
                </div>
                <div className="col-md-12 col-8">
                    <h6 className="mt-2">{t('register.vendorExpert')}</h6>
                </div>
            </div>
        </div>

        <div className="col-md-4  text-md-center text-left my-2">
            <div className="row mx-0">
                <div className="col-md-6 col-4 mx-auto">
                    <img src="/benefits.svg" className="img-fluid" />
                </div>
                <div className="col-md-12 col-8">
                    <h6 className="mt-2">{t('register.discount')}</h6>
                </div>
            </div>
        </div>
    </div> :<div id="no-online-selling" className="row  col-md-11 font-weight-bold c-magenta my-3 mx-auto">
        <div className="col-md-4  text-md-center text-left my-2">
            <div className="row mx-0">
                <div className="col-md-6 col-4 mx-auto">
                    <img src="/free-registration.svg" className="img-fluid" />
                </div>
                <div className="col-md-12 col-8">
                    <h6 className="mt-2">{t('register.earlyBird')}</h6>
                </div>
            </div>
        </div>

        <div className="col-md-4  text-md-center text-left my-2">
            <div className="row mx-0">
                <div className="col-md-6 col-4 mx-auto">
                    <img src="/benefits.svg" className="img-fluid" />
                </div>
                <div className="col-md-12 col-8">
                    <h6 className="mt-2">{t('register.vendorExpert')}</h6>
                </div>
            </div>
        </div>

        <div className="col-md-4  text-md-center text-left my-2">
            <div className="row mx-0">
                <div className="col-md-6 col-4 mx-auto">
                    <img src="/4-step-integration.svg" className="img-fluid" />
                </div>
                <div className="col-md-12 col-8">
                    <h6 className="mt-2">{t('register.discount')}</h6>
                </div>
            </div>
        </div>
    </div>
}