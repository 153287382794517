/* eslint-disable */

function Section7(props) {
  return (
    <div className="my-3 pb-5 border-bottom">
      <div className="container ">
        <div className="row my-4">
          <div className="col-md-12 mb-40">
            <h1 className="ff-literal fs-36 text-center c-dark-blue lh-45">
              {props.title}
            </h1>
            <p className="fw-100 fs022 lh-31 fs-21 text-center c-dark-blue">
              {props.text}
            </p>
          </div>
          {props.promo.map((prom, index) => {
            return (
              <div className="col-lg-4 homepage-single-pdf-wrapper my-3">
                  <a
                  target="_blank"
                  rel="noreferrer"
                  href={prom?.link}
                >
                  <img
                    className="homepage-single-pdf"
                    alt="Thumnbail of Vendor Promo"
                    style={{ borderRadius: 5 }}
                    width={"100%"}
                    src={prom?.featured_image}
                  />
                  </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={prom?.link}
                >
                  <h3 className="mt-2 fs-22 c-dark-blue text-left">{prom?.title}</h3>
                </a>
              </div>
            );
          })}

          <div className="col-md-4 mx-auto text-center my-3"><a target="_blank" href="https://insights.vendor.com.mk" class="header-button">Погледни ги сите новости</a></div>
        </div>
      </div>
    </div>
  );
}

export default Section7;
