/* eslint-disable */
import React, { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser';

import heroBanner from './../../assets/images/vendor-hero-2.png';
import robotAssistant from './../../assets/images/robot.svg';
import kubernetes from './../../assets/images/kubernetesBackground.svg';

import { Helmet } from "react-helmet";

import Header from "../components/landing/Header";
import Logos from "../components/landing/logos";
import Footer from "../components/landing/footer";
import Section6 from "../components/landing/section_6";
import { useTranslation } from 'react-i18next';

import axios from "axios";
import { stateIsLoaded } from "../../services/stateHelpers";
import { useDispatch, useSelector } from "react-redux";
import { getInsightsAction } from "../../redux/actions/vendorActions";
import { data } from "jquery";
import { useHistory, useParams } from "react-router-dom";
import { GET_SINGLE_INSIGHT_API } from "../../services/api";
import { browserHistory } from 'react-router';
import * as qs from 'query-string';


import landingData from '../../assets/api/mk/front.json'
import modulesData from '../../assets/api/mk/modules.json'

function Insights(props) {
  const { push } = useHistory()
  let { params } = useParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [isInsightShown, setIsInsightShown] = useState(false);
  const [shownInsightId, setShownInsightId] = useState(0);

  // const insightClicked = (id) => {
  //   setIsInsightShown(true);
  //   setShownInsightId(id);
  //   fetchInsight(id);
  //   push('/insights?insight=' + id)
  // }


  const handleBackClick = () => {

    console.log("BACK " + isInsightShown);
    if (isInsightShown)
      setIsInsightShown(false);
  }

  const { listen } = useHistory()
  useEffect(() => {
    const unlisten = listen((location) => {
      handleBackClick();
    })

    // remember, hooks that add listeners
    // should have cleanup to remove them
    return unlisten
  }, [])


  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    
    <div>
       <Helmet titleTemplate="Vendor - %s">
        <meta charSet="utf-8" />
        <title>Модули</title>
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png"/>
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png"/>
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
        <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/manifest.json"/>
        <meta name="msapplication-TileColor" content="#ffffff"/>
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
        <meta name="theme-color" content="#ffffff"/>
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta name="author" content="Vendor" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />

        <meta property="og:title" content="Vendor - Rethink selling" />
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          property="og:image"
          content="https://vendor.com.mk/facebook-thumbnail.jpg"
        />
        <meta property="og:url" content="https://vendor.com.mk/" />

        <meta name="twitter:title" content="Vendor - Rethink selling" />
        <meta
          name="twitter:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          name="twitter:image"
          content="https://vendor.com.mk/twitter-thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div>
        <Header></Header>

        <div className="insightsWrapper mb-4 py-5">
          <div className="container">
            {/* Title */}
            <div class="">
              <div className=" justify-content-start">
                <h3 className="custom-label ">
                  {t('servers.header')}
            </h3>
                {/* Description */}
                <h6 className="c-indigo" style={{ fontSize: 16 }} >
                  {t('servers.subHeader')}
            </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div class="row align-items-center">
            <div class="col-md-6">
              <h3 class="c-indigo mb-4">Cloud Решение базирано на Kubernetes</h3>
              <div class="styledList">
                <div class="styledListItem mb-3">
                  <div class="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img src="/circle.svg" width="20px" class="circle mr-1"/>
                      <h4 class="fs-20 c-indigo">сервери скалабилни до бесконечност</h4>
                  </div>
                </div>
                <div class="styledListItem mb-3">
                  <div class="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img src="/circle.svg" width="20px" class="circle mr-1"/>
                      <h4 class="fs-20 c-indigo">секогаш достапна е-продавница</h4>
                  </div>
                </div>
                <div class="styledListItem mb-3">
                  <div class="justify-content-start listHeading d-flex align-items-md-center align-items-start mb-2">
                    <img src="/circle.svg" width="20px" class="circle mr-1"/>
                      <h4 class="fs-20 c-indigo">бекап на секоја минута</h4>
                  </div>
                </div>
                </div>
            </div>
            <div class="col-md-6">
              <img src={kubernetes} class="img-fluid"></img>
            </div>
            <div class="col-md-12 my-4">
              <p class="fs-18">Vendor го користи системот Kubernetes при што вашата е-продавница без разлика на бројот на посетители, ја скалира серверската инфраструктура до бесконечност. Тоа значи дека доколку е потребно еден или повеќе дополнителни сервери, Kubernetes ќе ги подигне во моментот, а ќе ги изгаси во оној момент кога веќе не се потребни за опслужување на корисниците на е-продавницата.</p>
              
              <div class="alert alert-primary my-3" role="alert">
                  <h5>Бесплатни сервери</h5>
                  <p class="fs-17">Vendor според просечниот број на активни корисници во одреден момент, дефинира лимити кои овозможуваат твојата е-продавница да опслужи голем број на корисници без притоа да имате дополнителен трошок за сервери.
                  </p>
                  </div>
            
          <h5 class="c-indigo mb-3 mt-5">Дополнителни трошоци за сервер</h5>
           <p>
            Во плановите за користење на Vendor електронската продавница е предвидено бесконечен простор, како и неограничен сообраќај и лимит на активни корисници кои се регулира со политиката за фер користење.
           <br/><br/>Според таа политика дополнителни трошоци за сервери може да се генерираат само ако твојата е-продавница констатно опслужува голем број на активни корисници во еден момент.
           Во прилог е рефернтна табела за серверите кои Kubernetes може да ги подигне според бројот на активни корисници на твојата е-продавница, но тоа да се случи само ако е навистина потребно.
           </p>
          
          <table class="w-100 fees-table">
                    <thead>
                     
                      <tr class="table-row-header">
                        <th>Тип на сервер</th>
                        <th>Активни корисници во еден момент</th>
                        <th>Цена по час</th>
                      </tr>
                    </thead>
                    <tr>
                        <td>2 GB RAM, 1 CPU, 50 GB SSD</td>
                        <td>100-300</td>
                        <td>пресметано во претплатата</td>
                      </tr>
                      <tr>
                        <td>4 GB RAM, 2 CPU, 80 GB SSD</td>
                        <td>300-500</td>
                        <td>2,5 ден./час</td>
                      </tr>
                      <tr>
                        <td>8 GB RAM, 4 CPU, 160 GB SSD</td>
                        <td>500-1000</td>
                        <td>5 ден./час</td>
                      </tr>
                      <tr>
                        <td>16 GB RAM, 8 CPU, 320 GB SSD</td>
                        <td>1000-2000</td>
                        <td>10 ден./час</td>
                      </tr>
                      <tr>
                        <td>32 GB RAM, 16 CPU, 640 GB SSD</td>
                        <td>2000-3000</td>
                        <td>20 ден./час</td>
                      </tr>
                  </table>
                  <p class="small text-muted text-center my-3">Прикажаните цени се без ДДВ</p>
                  <div class="alert alert-primary my-3" role="alert">
                  <h6>Важни информации</h6>
                 Дополнителните трошоци се фактурираат само доколку е-продавницата го надминува предвидениот број на активни корисници во еден момент, а притоа за неа е подигнат дополнителен сервер.
                 <br/><br/>Како референца, просечен број на активни корисници во еден момент кај стандардните е-продавници е 20-50, што значи дека надминувањето над предвидените лимити вклучени во претплатата би се случувало само во ретки случаеви.
                  <br/><br/>Цените во табелата се  превземени од Linode, чии цени се изразени во долари, а Vendor кон своите корисници ги фактурира во денарска противвредност.
                  
                  <br/><br/>Бидејќи бројот на серверите кои може да се подигнат е динамичен, Vendor се обврзува на сопственикот на е-продавницата да му фактурира фер и транспаретно согласно извештаите кои ќе ги изгенерира за користењето на е-продавницата.
                  <br/><br/>Дополнително, Vendor се обврзува да не фактурира за дополнителни серверски трошоци доколку истите се помали од 5% од месечната претплата.
              </div>


            </div>
          </div>

          <Section6
            title={landingData?.section_6?.title}
            button_label={landingData?.section_6?.button_label}
            key={"seciont 6"}
          />
        </div>
        <Footer />
      </div>
      {/* )} */}
    </div>
  );
}

export default Insights;
