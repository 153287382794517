/* eslint-disable */
import { Link } from 'react-router-dom';
import ReactHtmlParser  from 'react-html-parser';

function Section4(props) {

    let steps = props.steps;

    return (
        <div className="bg-pale-blue py-90 mt-90">
            <div className="container">
                <div className="row mb-5 pb-4">

                    <div className="col-md-12">
                        <h2 className="ff-literal fs-36 text-center c-dark-blue lh-45 disable-br-mobile">{ReactHtmlParser(props.title)}</h2>
                        <p className="fw-100 fs-17 lh-27 text-center c-gray pt-3 pb-md-5 pb-0">{props.tagline}</p>
                    </div>

                    {
                        steps.map((step, i) => {
                            return (
                                <div key={i + 2} className="col-lg-3 col-md-6 col-sm-6 col-xs-12 single-step-wrapper">
                                    <img className="d-flex my-0 mx-auto steps-icon" width={'90px'} src={step.icon} alt={step.heading} />
                                    <div className="steps-text-wrapper">
                                        <div className="steps-number-heading-wrap">
                                            <div className="text-center steps-number-circle">{i + 1}</div>
                                            <h6 className="how-to-steps-heading">{step.heading}</h6>
                                        </div>
                                        <p className="how-to-steps-description c-gray">
                                            {step.description}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="text-center">
                <a href="/register-business" id="how-to-register-button" className="pink-button"  >{props.button_label}</a>

                </div>
            </div>
        </div>
    )
}

export default Section4;
