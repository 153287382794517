import { makeVendorCoreClient } from '../client.js';

export function authRegister(requestBody) {
  return makeVendorCoreClient()
    .then((client) => {
      return client.apis.v1['register_api_v1_auth_register__post']({
        token_lifetime: 86400,
      }, {
        requestBody,
      }).then((r) => r.body)
    });
}
