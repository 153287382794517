/* eslint-disable */
import React, { useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import _capitalize from 'lodash/capitalize';
import { makeValidate, TextField } from 'mui-rff';
import * as Yup from 'yup';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Footer from './../components/landing/footer';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Header from '../components/landing/Header';
import { Helmet } from 'react-helmet';
import PhoneInput from '../../form/PhoneInput.js';
import { authRegister } from '../../api/vendor-core/v1';
import { useTranslation } from 'react-i18next';
import { BasicCheckboxes } from '../../form/Checkbox.js';
import { useCommonValidation } from '../../form/validation.js';
import PasswordInput from '../../form/PasswordInput.js';
import { ManageCookiesContext } from '../../contexts/ManageCookiesContext.jsx';
import { VariousPropContext } from '../../contexts/VariousPropContext';
import { LocaleContext } from '../../App.js';
import { FormIcons } from '../components/register/FormIcons';
import { useWindowSize } from '../../services/UseWindowSize';
import ClickableToolTip from '../../services/ClickableTooltip';
import { EmailInput } from '../../form/EmailInput';

function trimDashesFromString(phoneNumber) {
  const phoneNumbers = phoneNumber.split('-');
  if(phoneNumbers.length > 1){
    return `${phoneNumbers[0]}${phoneNumbers[1]}${phoneNumbers[2]}`;
  }
  return phoneNumbers[0];
}

function Lead() {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  const { cookiesData } = React.useContext(ManageCookiesContext);
  const { plan, subscription_period } = React.useContext(VariousPropContext);
  const { locale } = React.useContext(LocaleContext);
  const cookies = cookiesData;
  const { statistics_cookie } = cookies;
  const isBusiness = _includes(pathname, 'register-business');
  const windowSize = useWindowSize();
  const onRegularRegister = async values => {
    const {
      first_name,
      last_name,
      email,
      phonePrefix,
      phoneNumber,
      password,
      presale,
      termsOfServiceMarketing
    } = values;

    const phoneNumberWithoutDashes = trimDashesFromString(phoneNumber);
    const mobile_phone = `${phonePrefix}${phoneNumberWithoutDashes}`;
    const consents = {
      direct_marketing:
        termsOfServiceMarketing && termsOfServiceMarketing.length !== 0,
      terms_of_service: true,
      privacy_policy: true
    };
    const callback_url = process.env.REACT_APP_VENDOR_MK;
    const want_to_sell_online =
       values.wants_to_sell_online.length !== 0 && values.wants_to_sell_online[0];
    const data = {
      first_name: _capitalize(first_name),
      last_name: _capitalize(last_name),
      mobile_phone,
      email,
      site_id: 0,
      password,
      cookies,
      presale: { ...presale, want_to_sell_online, plan, subscription_period },
      consents,
      locale: statistics_cookie ? locale : 'mk',
      callback_url
    };

    try {
      setServerError(null);
      setIsLoading(true);
      await authRegister(data);
      const successPath = isBusiness ? '/successful-register-business' : '/successful-register'
      push(successPath);
    } catch (e) {
      const detail = e?.response?.obj?.detail;
      if (detail) {
        return {
          email: t(`vendorCore.validation.${detail}`)
        };
      } else {
        setServerError(t('register.registrationFailed'));
      }
    } finally {
      setIsLoading(false);
    }
  };


  const { alphanumeric, email, strongPassword } = useCommonValidation();

  const fields = [
    {
      name: 'first_name',
      autoComplete: 'given-name',
      component: TextField,
      placeholder: t('register.name')
    },
    {
      name: 'last_name',
      autoComplete: 'family-name',
      component: TextField,
      placeholder: t('register.lastName')
    },
    {
      name: 'email',
    autoComplete:'email',
    component: EmailInput,
    placeholder: t('register.email')
    },
    {
      name: 'phone',
      autoComplete:'tel-national',
      component: PhoneInput,
      placeholder: t('register.phone'),
      InputProps: { inputMode: 'numeric' },
      // fieldProps: {
      //   parse: phoneParser,
      // }
    },
    {
      name: 'password',
      component: PasswordInput,
      autoComplete:'new-password',
      placeholder: t('register.password'),
      InputProps: { type: 'password' }
    }
  ];
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const displaySecondVendorCondition = true;

  const schema = useMemo(() => {
    return Yup.object().shape({
      first_name: alphanumeric.required(t('register.validation.name')),
      last_name: alphanumeric.required(t('register.validation.lastName')),
      email: email.required(t('register.validation.email')),
      phoneNumber: Yup.string().required(t('register.validation.phone')),
      password: strongPassword.required(t('register.validation.password')),
      termsOfService: Yup.array()
        .required(t('register.validation.termsOfService'))
        .min(1, t('register.validation.termsOfService'))
    });
  }, []);
  const validate = makeValidate(schema);

  const formInitialValues = {
    phonePrefix: '389',
    termsOfService: [true],
    termsOfServiceMarketing: [true],
    wants_to_sell_online: [isBusiness]
  };

  return (
    <div>
      <Helmet titleTemplate="Vendor - %s">
        <meta charSet="utf-8" />
        <title>Регистрација - Vendor</title>
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta name="author" content="Vendor" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />

        <meta property="og:title" content="Vendor - Rethink selling" />
        <meta
          property="og:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          property="og:image"
          content="https://vendor.com.mk/facebook-thumbnail.jpg"
        />
        <meta property="og:url" content="https://vendor.com.mk/" />

        <meta name="twitter:title" content="Vendor - Rethink selling" />
        <meta
          name="twitter:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          name="twitter:image"
          content="https://vendor.com.mk/twitter-thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <div className="" style={{ minHeight: 500 }}>
        <div className="container pt-3">
        {isBusiness && (
                      <h3 className="custom-label text-center mb-0 mt-5 fs-36 c-dark-blue fw-900">
                      {t('register.header1')}
                      <br />
                      {t('register.header2')}
                    </h3>

                          )}

          <Form
            validate={validate}
            initialValuesEqual={_isEqual}
            initialValues={formInitialValues}
            onSubmit={onRegularRegister}
            render={({ handleSubmit, values }) => {
              return (
                <>
                  <div className="col-md-9 px-0 mx-auto py-4">
                  {isBusiness && (
                        <FormIcons business={isBusiness} />
                          )}

             
                  </div>
                  <div className="row pb-3" style={{ borderRadius: 16 }}>
                    <div
                      className="mx-auto registration-square-wrapper col-xl-4 col-lg-5 col-md-7 mx-auto px-md-4"
                      style={{ paddingRight: '4px' }}
                    >
                      <div className="register-cta col-md-12 text-center mt-md-5 mt-0">
                        <img
                          src="/semicircular-up-arrow.svg"
                          className="cta-arrow"
                        />
                        <h3 className="c-indigo pt-2 text-left">
                          {t('register.registerNow')}
                        </h3>
                      </div>
                      <form className="col-md-12" onSubmit={handleSubmit}>
                        <Box
                          display="flex"
                          style={{ flexFlow: 'column', marginTop: '20px' }}
                        >
                          {fields.map(field => {
                            return (
                              <field.component
                                key={field.name}
                                name={field.name}
                                className={`${classes.input} registration-input`}
                                placeholder={field.placeholder}
                                InputProps={{
                                  autoComplete: field.autoComplete,
                                  ...field.InputProps
                                }}
                                fieldProps={field.fieldProps}
                              />
                            );
                          })}
                          {isBusiness && (
                            <TextField
                              name="presale.store_name"
                              placeholder={t('register.storeName')}
                              autoComplete="organization"
                              className={`${classes.input} registration-input`}
                            />
                          )}
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            className={`${classes.checkbox}`}
                          >
                            <BasicCheckboxes
                              data={[
                                {
                                  label: t('register.termsOfService'),
                                  value: true
                                }
                              ]}
                              name="termsOfService"
                              className={`${classes.termsAndConditions}`}
                              disabled
                            />
                            {windowSize?.width <= 576 ? (
                              <ClickableToolTip
                                title={t('register.termsOfServiceTooltip')}
                              >
                                {' '}
                                <img src="info.svg" width="15px" />
                              </ClickableToolTip>
                            ) : (
                              <Tooltip
                                title={t('register.termsOfServiceTooltip')}
                              >
                                <img src="info.svg" width="15px" />
                              </Tooltip>
                            )}
                          </Box>
                          {displaySecondVendorCondition && (
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              className={`${classes.checkbox}`}
                            >
                              <BasicCheckboxes
                                data={[
                                  {
                                    label: t('register.termsOfService2'),
                                    value: true
                                  }
                                ]}
                                name="termsOfServiceMarketing"
                                className={`${classes.termsAndConditions}`}
                              />
                                 {windowSize?.width <= 576 ? (
                              <ClickableToolTip
                              title={t('register.termsOfServiceTooltip2')}
                              >
                                {' '}
                                <img src="info.svg" width="15px" />
                              </ClickableToolTip>
                            ) : (
                              <Tooltip
                                title={t('register.termsOfServiceTooltip2')}
                              >
                                <img src="info.svg" width="15px" />
                              </Tooltip>
                            )}

                            </Box>
                          )}
                          {serverError && (
                            <Alert severity="error" color="error">
                              <p>{serverError}</p>
                            </Alert>
                          )}
                          <div className="w-100 text-center">
                            <button
                              id="register-business-button"
                              onClick={handleSubmit}
                              type="submit"
                              disabled={isLoading}
                              className="pink-button fs-18 mt-2 text-center w-100"
                            >
                              {t('register.submitButton')}
                            </button>
                          </div>
                        </Box>
                      </form>
                    </div>
                  </div>
                </>
              );
            }}
          />
          <div className="pb-5 col-md-8 mx-auto" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

const useStyles = makeStyles({
  input: {
    marginTop: '0.8rem !important',
    minHeight: '50px',
    fontWeight: '100',
    fontSize: '17px !important'
  },
  checkbox: {
    marginTop: '1rem !important',
    minHeight: '30px',
    fontWeight: '100'
  },
  termsAndConditions: {
    marginTop: '1rem !important',
    fontSize: '6px !important',
    fontWeight: '100 !important'
  }
});

export default Lead;
