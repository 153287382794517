import React from 'react';
import { Checkboxes } from 'mui-rff';
import { Box, makeStyles } from '@material-ui/core';
import { BasicLabel } from './Labels.js';

export const BasicCheckboxes = ({ name, data = [], required, label, className, disabled }) => {
  const classes = useStyles();

  const _data = data.map(d => ({
    label: (
        <Box ml={2} mt="1px">
          <BasicLabel>{d.label}</BasicLabel>
        </Box>
    ),
    value: d.value,
  }));

  const colorClassName = disabled ? classes.disabled : classes.enabled;
  return (
      <Box display="flex" alignItems="flex-start" mb={1} className={className}>
        <Checkboxes
            className={`${classes.checkbox} ${className} ${colorClassName}`}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            data={_data}
            name={name}
            formControlLabelProps={{
              style: {
                marginLeft: 0,
              },
            }}
            required={required}
            label={label}
            disabled={disabled}
        />
      </Box>
  );
};


const useStyles = makeStyles(() => ({
  checkbox: {
    padding: 0,
    width: 20,
    height: 20,
  },
  enabled: {
    color: '#35A9E1 !important'
  },
  disabled: {
    color: 'lightgrey !important'
  }
}));

