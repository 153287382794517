import * as React from 'react';
import { Paper, makeStyles } from '@material-ui/core';
import CookiePrivacy from './CookiePrivacy.jsx';
import ManageCookies from './ManageCookies.jsx';
import { ManageCookiesContext } from '../../../contexts/ManageCookiesContext.jsx';

export default function Cookies() {
  const [ activeView, setActiveView ] = React.useState('privacy');
  const { showDialog } = React.useContext(ManageCookiesContext);
  const classes = useStyles();


  if (!showDialog) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Paper className={classes.paper}>
        { activeView === 'privacy' ? (
          <CookiePrivacy setActiveView={setActiveView} />
        ) : (
          <ManageCookies setActiveView={setActiveView} />
        )}
      </Paper>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    zIndex: 10,
    width: '430px',
    borderRadius: '20px',
    boxShadow: '0px 0px 35px #00000021',
    [theme.breakpoints.down('500')]: {
      width: 'calc(100% - 40px)',
      left: '20px',
    }
  },
  paper: {
    padding: '45px',
    borderRadius: '20px !important',
    boxShadow: '0px 0px 35px #00000021',
    [theme.breakpoints.down('500')]: {
      padding:'35px'
    }
  }
}))
