/* eslint-disable */

function Section9(props) {
    return (
        <div className="mt-5 pt-5 bg-pale-blue">
            <div className="container">

                <div className="pt-5">
                    <h1 className="text-center ff-literal fs-32 lh-42 c-indigo mb-25 max-width-750 ml-auto mr-auto">
                        {props.title}</h1>
                    <p className="text-center fw-100 fs-18 lh-31 c-gray mb-25 max-width-750 ml-auto mr-auto">
                        {props.text}
                    </p>
                    <video controls={true} style={{ width: '100%', marginTop: 30 }} loop autostart="true" autoPlay muted type="video/mp4" src={props.video} ></video>

                </div>
            </div>

        </div>
    )
}

export default Section9;
