/* eslint-disable */
import React from 'react';
import mk from '../../assets/api/mk/vendor-documents.json';
import en from '../../assets/api/en/vendor-documents.json';
import sq from '../../assets/api/sq/vendor-documents.json';
import ReactHtmlParser from 'react-html-parser';
import Header from '../components/landing/Header.js';
import Footer from '../components/landing/footer.js';
import { LocaleContext } from '../../App.js';

const dataMap = {
  mk,
  en,
  sq,
}

export default () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const { locale } = React.useContext(LocaleContext);
  const data = dataMap[locale];
  return (
    <div>
      <Header />
      <div className="insightsWrapper mb-4 py-5">
        <div className="container">
          <div className="">
            <div className=" justify-content-start">
              <h3 className="custom-label ">
                {data.title}
              </h3>
              <h6 className="c-indigo" style={{ fontSize: 16 }}>
                {data.description}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4 py-5">
        <div className="container">
          <div className="row mx-0">
        {data?.documents.map((item, index) => {
                  return (
                    <div class="col-md-12 px-0">
                      <div className="row mx-0">
                        <div className="col-md-12 px-0 mb-3 border-bottom">
                        <a href={item.url} target="_blank"><h6 className="c-indigo">{item.title}</h6></a>
                        </div>
                      </div>
                    </div>
                  )
          })
        }
        </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
