/* eslint-disable */
import React, { useEffect, useState } from "react";
import ReactHtmlParser from 'react-html-parser';

import heroBanner from './../../assets/images/vendor-hero-2.png';
import robotAssistant from './../../assets/images/robot.svg';
import robotWizardFlow from './../../assets/images/robot-wizard.svg';
import { Helmet } from "react-helmet";

import Header from "../components/landing/Header";
import Logos from "../components/landing/logos";
import Footer from "../components/landing/footer";
import Section6 from "../components/landing/section_6";
import { useTranslation } from 'react-i18next';

import axios from "axios";
import { stateIsLoaded } from "../../services/stateHelpers";
import { useDispatch, useSelector } from "react-redux";
import { getInsightsAction } from "../../redux/actions/vendorActions";
import { data } from "jquery";
import { useHistory, useParams } from "react-router-dom";
import { GET_SINGLE_INSIGHT_API } from "../../services/api";
import { browserHistory } from 'react-router';
import * as qs from 'query-string';


import landingData from '../../assets/api/mk/front.json'
import modulesData from '../../assets/api/mk/modules.json'

function Insights(props) {
  const { push } = useHistory()
  let { params } = useParams();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [isInsightShown, setIsInsightShown] = useState(false);
  const [shownInsightId, setShownInsightId] = useState(0);

  // const insightClicked = (id) => {
  //   setIsInsightShown(true);
  //   setShownInsightId(id);
  //   fetchInsight(id);
  //   push('/insights?insight=' + id)
  // }


  const handleBackClick = () => {

    console.log("BACK " + isInsightShown);
    if (isInsightShown)
      setIsInsightShown(false);
  }

  const { listen } = useHistory()
  useEffect(() => {
    const unlisten = listen((location) => {
      handleBackClick();
    })

    // remember, hooks that add listeners
    // should have cleanup to remove them
    return unlisten
  }, [])


  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    
    <div>
       <Helmet titleTemplate="Vendor - %s">
        <meta charSet="utf-8" />
        <title>Модули</title>
        <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png"/>
        <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png"/>
        <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
        <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
        <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
        <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
        <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/manifest.json"/>
        <meta name="msapplication-TileColor" content="#ffffff"/>
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
        <meta name="theme-color" content="#ffffff"/>
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta name="author" content="Vendor" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />

        <meta property="og:title" content="Vendor - Rethink selling" />
        <meta
          name="description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          property="og:image"
          content="https://vendor.com.mk/facebook-thumbnail.jpg"
        />
        <meta property="og:url" content="https://vendor.com.mk/" />

        <meta name="twitter:title" content="Vendor - Rethink selling" />
        <meta
          name="twitter:description"
          content="Онлајн платформа за креирање на е-продавница во само неколку чекори, без кодирање и без чекање."
        />
        <meta
          name="twitter:image"
          content="https://vendor.com.mk/twitter-thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div>
        <Header></Header>

        <div className="insightsWrapper mb-4 py-5">
          <div className="container">
            {/* Title */}
            <div class="">
              <div className=" justify-content-start">
                <h3 className="custom-label ">
                  {t('emailServer.header')}
            </h3>
                {/* Description */}
                <h6 className="c-indigo" style={{ fontSize: 16 }} >
                  {t('emailServer.subHeader')}
            </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div class="row">
          <div class="col-md-12">
            <h3 class="c-indigo">Користење на е-маил сервер</h3>
            <p>Постојат три начини за користење на е-маил серверот на Vendor доколку не сакате или не постои можност да го користите вашиот е-маил провајдер како Gmail, Outlook, Yahoo, Telekom  и сл.</p>
            <ol>
              <li>Користење на е-маил адресите од Vendor</li>
              <li>Креирање на е-маил адреса со името на твојот бизнис на @vendor.com.mk. Пример: imeNaBiznis@vendor.com.mk</li>
              <li>Поврзување на твојот домен со е-маил серверите на Vendor поддржани од нашиот партнер Sendinblue</li>
            </ol>

            <h5 class="c-indigo mb-3 mt-5">Опција 1 - Користење на е-маил адреса на @vendor.com.mk или @vendorglobal.com</h5>
            <table class="w-100 fees-table">
                    <thead>
                     
                      <tr class="table-row-header">
                        <th>Е-маил адреса</th>
                        <th>Месечно плаќање</th>
                        <th>Годишно плаќање (-10%)</th>
                      </tr>
                    </thead>
                    <tr>
                        <td>info@vendor.com.mk</td>
                        <td>0 ден.</td>
                        <td>0 ден.</td>
                      </tr>
                      <tr>
                      <td>newsletter@vendor.com.mk</td>
                        <td>0 ден.</td>
                        <td>0 ден.</td>
                      </tr>
                      <tr>
                      <td>info@vendorglobal.com</td>
                        <td>0 ден.</td>
                        <td>0 ден.</td>
                      </tr>
                      <tr>
                      <td>newsletter@vendorglobal.com</td>
                        <td>0 ден.</td>
                        <td>0 ден.</td>
                      </tr>
                  </table>
                  <p class="small text-muted text-center my-3">Прикажаните цени се без ДДВ</p>
    

                  <h5 class="c-indigo mb-3 mt-5">Опција 2 - Креирање на е-маил адреса на @vendor.com.mk или @vendorglobal.com</h5>
           <p>
              Со оваа опција може да креирате една или повеќе е-маил адреси на @vendor.com.mk. Е-маил адресите може да ги користите за трансакционите и маркетинг е-маил пораките кои доаѓаат на вашата е-продавница.
              Дополнително добивате и пристап каде може преку овие е-маил адреси да комуницирате со вашите купувачи.
              Е-маил адресите на @vendor.com.mk  се поддржани од нашиот партнер Zoho - Zoho.com</p>
          
          <table class="w-100 fees-table">
                    <thead>
                     
                      <tr class="table-row-header">
                        <th>Е-маил адреса и простор</th>
                        <th>Месечно плаќање</th>
                        <th>Годишно плаќање (-10%)</th>
                      </tr>
                    </thead>
                    <tr>
                        <td>1 е-маил адреса - 5GB</td>
                        <td>80 ден.</td>
                        <td>860 ден.</td>
                      </tr>
                      <tr>
                      <td>1 е-маил адреса - 10GB</td>
                        <td>120 ден.</td>
                        <td>1.080 ден.</td>
                      </tr>
                  </table>
                  <p class="small text-muted text-center my-3">Прикажаните цени се без ДДВ</p>
            


                  <h5 class="c-indigo mb-3 mt-5">Опција 3 - Поврзување на твојот домен со е-маил серверите на Vendor</h5>
                  <p>Со оваа опција може да ја поврзеш твојата бизнис е-маил адреса со нашите е-маил сервери. Toa ти ќе овозможи да ги користиш нашите е-маил сервери според  ценовник за испраќање на е-маил пораки.<br/><br/>Со цел да се поврзе твојата е-маил адреса, од твоја страна ќе биде потребна верификација на е-маил адресата или на доменот на која таа се наоѓа.</p>
                  <div class="alert alert-primary my-3" role="alert">
                  <h6>Квалитет на е-маил пораки</h6>
                  Со цел Vendor е-маил серверот да одржува позитивен ранг и да биде препознаен од големите е-маил провајдери како Gmail, Yahoo, Outlook, Hotmail, а притоа е-маил пораките кои доаѓаат од е-продавниците на Vendor да не пристигнуваат во Spam и Junk, Vendor има право да го регулира испраќањето на е-маил пораките кои доаѓаат од твојата е-маил адреса. 
                  </div>


           <h5 class="c-indigo mb-3 mt-5">Дедицирана IP адреса</h5>
           <p>Дедицираната IP адреса служи за испраќање на голем број маркетинг и трансакциони пораки. Таа е специјално дизајнирана да биде препознаена од големите е-маил провајдери како Gmail, Yahoo, Outlook, Hotmail  и др. и доколку рангот на твојата дедицирана IP адреса е задоволителен твоите е-маил пораки нема да пристигнуваат во Spam и Junk фолдерот.</p>
          
          <table class="w-100 fees-table">
                    <thead>
                     
                      <tr class="table-row-header">
                        <th>Дедицирана IP адреса</th>
                        <th>Обем на е-маил пораки</th>
                        <th>Годишно плаќање</th>
                      </tr>
                    </thead>
                    <tr>
                        <td>1 дедицирана IP адреса</td>
                        <td>Вкупно 50.000 - 200.000 / месечно</td>
                        <td>15.300 ден.</td>
                      </tr>
                  </table>
                  <p class="small text-muted text-center my-3">Прикажаните цени се без ДДВ</p>
                  <div class="alert alert-primary my-3" role="alert">
                  <h6>Важни информации</h6>
                  Дедицирана IP адреса е наменета за оние кои испраќаат поголем број на е-маил пораки на дневна, недела и месечна основа.
                  <br/>Дедицирана IP адреса е неопходна доколку испраќате на повеќе од 3000 корисници дневно, повеќе од 3000 корисници во една кампања или пак доколку имате над 300 трансакциони е-маил пораки дневно.
                  </div>

        <h3 class="c-indigo mb-3 mt-5">Ценовник за е-маил пораки</h3>
          <h5 class="c-indigo mb-3 mt-5">Ценовник за трансакциони е-маил пораки</h5>
          <table class="w-100 fees-table">
                    <thead>
                      <tr class="table-row-header">
                        <th>Е-маил пораки</th>
                        <th>Месечно плаќање</th>
                        <th>Годишно плаќање (-10%)</th>
                      </tr>
                    </thead>
                    <tr>
                        <td>до 1,000</td>
                        <td>Пресметано во претплатата</td>
                        <td>Пресметано во претплатата</td>
                      </tr>
                      <tr>
                        <td>до 10,000</td>
                        <td>1.530 ден.</td>
                        <td>1.370 ден.</td>
                      </tr>
                      <tr>
                        <td>до 20,000</td>
                        <td>2.400 ден.</td>
                        <td>2.160 ден.</td>
                      </tr>
                      <tr>
                        <td>до 40,000</td>
                        <td>3.400 ден.</td>
                        <td>3.060 ден.</td>
                      </tr>
                      <tr>
                        <td>до 60,000</td>
                        <td>4.300 ден.</td>
                        <td>3.870 ден.</td>
                      </tr>
                      <tr>
                      <td>до 100,000</td>
                        <td>5.400 ден.</td>
                        <td>4.800 ден.</td>
                      </tr>                      
                  </table>
                  <p class="small text-muted text-center my-3">Прикажаните цени се без ДДВ</p>

          <h5 class="c-indigo mb-3 mt-5">Ценовник за маркетинг е-маил пораки</h5>
          <table class="w-100 fees-table">
                    <thead>
                      <tr class="table-row-header">
                        <th>Е-маил пораки</th>
                        <th>Месечно плаќање</th>
                        <th>Годишно плаќање (-10%)</th>
                      </tr>
                    </thead>
                    <tr>
                        <td>до 300</td>
                        <td>Пресметано во претплатата</td>
                        <td>Пресметано во претплатата</td>
                      </tr>
                      <tr>
                        <td>до 10,000</td>
                        <td>1.530 ден.</td>
                        <td>1.370 ден.</td>
                      </tr>
                      <tr>
                        <td>до 20,000</td>
                        <td>2.400 ден.</td>
                        <td>2.160 ден.</td>
                      </tr>
                      <tr>
                        <td>до 40,000</td>
                        <td>3.400 ден.</td>
                        <td>3.060 ден.</td>
                      </tr>
                      <tr>
                        <td>до 60,000</td>
                        <td>4.300 ден.</td>
                        <td>3.870 ден.</td>
                      </tr>                      
                      <tr>
                        <td>до 100,000</td>
                        <td>5.400 ден.</td>
                        <td>4.800 ден.</td>
                      </tr>
                  </table>
                  <p class="small text-muted text-center my-3">Прикажаните цени се без ДДВ</p>

          </div>
          </div>

          
          <Section6
            title={landingData?.section_6?.title}
            button_label={landingData?.section_6?.button_label}
            key={"seciont 6"}
          />
        </div>
        <Footer />
      </div>
      {/* )} */}
    </div>
  );
}

export default Insights;
