/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';

import CheckImage from '../../../../assets/icons/check2.png';

import 'swiper/swiper-bundle.css';

import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { EffectCoverflow, Pagination } from 'swiper';
import { IsDesktop } from '../../../../services/CustomMediaQueries';
import PricingTable from '../PricingTable';
SwiperCore.use([EffectCoverflow, Pagination]);

const styles = {
    plan: {
        // borderWidth: '15px',
        container: {
            border: '1px solid #C2C0DB',
            padding: 0,
            borderRadius: '15px',
            // display: 'inline-block',
            backgroundColor: '#FFFFFF',
            // minHeight: '600px',

            flexDirection: 'column',
            display: 'flex',
            minHeight: '620px',
        },
        title: {
            // backgroundColor: "#FFFFFF",
            color: "#343288",
            borderBottom: '1px solid #C2C0DB',
            // borderTopRadius: '20px',
            fontSize: '22px',
        },
        body: {
            backgroundColor: '#FFFFFF',
            justifyContent: 'space-between',
            flexDirection: 'column',
            display: 'flex',
            borderEndEndRadius: '14px',
            borderBottomLeftRadius: '14px',
        },
        wrapper: {
            // marginLeft: '-100px',
            // marginRight: '-100px',
            flexDirection: 'row-reverse',
            // paddingLeft: '100px',
            // paddiingRight: '100px',
            // alignItems: 'stretch',
            // minHeight: '900px'
            // justifyContent: 'space-around',
        }
    },
    selectedPlan: {
        // borderWidth: '15px',
        container: {
            border: '1px solid #343288',
            padding: 0,
            borderRadius: '15px',
            // display: 'inline-block',
            backgroundColor: '#343288',
            flexDirection: 'column',
            display: 'flex',
            minHeight: '620px',
        },
        title: {
            // backgroundColor: "#343288",
            color: "#FFFFFF",
            borderBottom: '1px solid #C2C0DB',
            // borderTopRadius: '20px',
            fontSize: '22px',
        },
        body: {
            backgroundColor: '#f2fafd',
            borderEndEndRadius: '14px',
            borderBottomLeftRadius: '14px',
            alignItems: 'stretch',
            justifyContent: 'space-between',
            flexDirection: 'column',
            display: 'flex',

        }
    }
}

function Step5({ data, stepIndex, wizardStateData }) {

    const [fix, setFix] = useState('');



    const handlePlanChange = (index) => {
        wizardStateData.steps[stepIndex].planIndex = index;

        setFix(index);
    }

    const size = useWindowSize();

    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });

        useEffect(() => {
           console.log()
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }

            window.addEventListener("resize", handleResize);

            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }, []);

        return windowSize;
    }


    const isDesktop = IsDesktop();


    return (
        <div style={isDesktop ? { marginLeft: -100, marginRight: -100 } : {}}>
            {data?.inputs?.map((input, inputIndex) => (

                size.width > 991 && (
                    <div className="col-4 d-inline-block" onClick={() => handlePlanChange(inputIndex)}>
                        <div style={wizardStateData.steps[stepIndex].planIndex === inputIndex ? styles.selectedPlan.container : styles.plan.container} >
                            {/* Title */}
                            <div style={wizardStateData.steps[stepIndex].planIndex === inputIndex ? styles.selectedPlan.title : styles.plan.title} className="text-center fw-900 p-3">
                                {input.title}
                            </div>
                            {/* Body */}
                            <div style={wizardStateData.steps[stepIndex].planIndex === inputIndex ? styles.selectedPlan.body : styles.plan.body} className="px-5 py-3 flex-grow-1">
                                <div>
                                    <div>
                                        {input.heading}
                                    </div>
                                    <div>
                                        {input.features.map((item, index) => (
                                            <div className="mt-3">
                                                <div className="c-darker-blue fs-13 mb-1" style={{ fontSize: 12 }}>
                                                    {item.label}
                                                </div>
                                                {item.features.map((feature, featureIndex) => (
                                                    <div>
                                                        {feature.text && (
                                                            // <div className="fs-17 fw-500 lh-18 c-indigo mb-1" style={{ position: 'relative' }}>
                                                            //     <img style={{ float: 'left', width: 20 }} width={'14px'} src={CheckImage} alt={'Shoping card'} />
                                                            //     {feature.text}
                                                            // </div>

                                                            <div className="fs-17 fw-500 lh-18 c-indigo mb-1" style={{ position: 'relative' }}>
                                                                {feature.text}
                                                                <div style={{ position: 'absolute', zIndex: 100, left: -14 - 10, top: 0 }}>
                                                                    <img width={'14px'} src={CheckImage} alt={'Shoping card'} />
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className="c-indigo mb-1" style={{ fontSize: 12 }}>
                                                            {feature.description}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* Price */}
                                <div className="text-center mt-2">
                                    <div className="mb-3">
                                        <div className="c-magenta fw-900 mb-0" style={{ fontSize: '30px' }}>{input.price}</div>
                                        <div className="c-magenta mb-1" style={{ fontSize: '12px' }}>{input.priceReocurrence}</div>
                                        <div style={{ fontSize: '10px' }}>{input.additionalFee}</div>
                                    </div>
                                    <Form.Check className="plans-check" checked={wizardStateData.steps[stepIndex].planIndex === inputIndex} type="radio" label={input.chooseButtonLabel} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ))
            }

            {
                size.width < 991 && (

                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        arrows={true}
                        EffectCube={true}
                        effect="coverflow"
                        pagination={{ clickable: true }}
                        style={{ paddingBottom: 50 }}
                    >
                        {data?.inputs?.map((input, inputIndex) => (
                            <SwiperSlide style={{ width: 200 }}>
                                <div onClick={() => handlePlanChange(inputIndex)}>
                                    <div style={wizardStateData.steps[stepIndex].planIndex === inputIndex ? styles.selectedPlan.container : styles.plan.container} >
                                        {/* Title */}
                                        <div style={wizardStateData.steps[stepIndex].planIndex === inputIndex ? styles.selectedPlan.title : styles.plan.title} className="text-center fw-900 p-3">
                                            {input.label}
                                        </div>
                                        {/* Body */}
                                        <div style={wizardStateData.steps[stepIndex].planIndex === inputIndex ? styles.selectedPlan.body : styles.plan.body} className="px-5 py-3 flex-grow-1">
                                            <div>
                                                <div>
                                                    {input.heading}
                                                </div>
                                                <div>
                                                    {input.features.map((item, index) => (
                                                        <div className="mt-3">
                                                            <div className="c-darker-blue fs-13 mb-1" style={{ fontSize: 12 }}>
                                                                {item.label}
                                                            </div>
                                                            {item.features.map((feature, featureIndex) => (
                                                                <div>
                                                                    {feature.text && (
                                                                        <div className="fs-17 fw-500 lh-18 c-indigo mb-1" style={{ position: 'relative' }}>
                                                                            <img style={{ float: 'left', width: 20 }} width={'14px'} src={CheckImage} alt={'Shoping card'} />
                                                                            {feature.text}
                                                                        </div>
                                                                    )}
                                                                    <div className="c-indigo mb-1" style={{ fontSize: 12 }}>
                                                                        {feature.description}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                            {/* Price */}
                                            <div className="text-center mt-2">
                                                <div className="mb-3">
                                                    <div className="c-magenta fw-900 mb-0" style={{ fontSize: '30px' }}>{input.price}</div>
                                                    <div className="c-magenta mb-1" style={{ fontSize: '12px' }}>{input.priceReocurrence}</div>
                                                    <div style={{ fontSize: '10px' }}>{input.additionalFee}</div>
                                                </div>
                                                <Form.Check className="plans-check" checked={wizardStateData.steps[stepIndex].planIndex === inputIndex} type="radio" label={input.chooseButtonLabel} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )

            }


            <div className='d-flex  flex-column justify-content-center my-5'>

            <h3 className="custom-label text-center mb-5" style={{ fontSize: '2rem' }}>
                Бенефити од продажните планови
            </h3>
            <PricingTable className="mb-4"  />
            </div>
        </div >
    )

}

export default Step5;




